/*!
 **********************************************************************************************
 * Company name: Jenex Technologies
 *
 * @file : authentication.service.ts
 * @file description: This file is for APIs login
 * @author : Asha Bheda
 *
 *********************************************************************************************/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient) { }

  login(username: string, password: string) {
    return this.http.post<any>(`/api/login`, { username: username, password: password })
      .pipe(map(user => {
        console.log('users ', user);
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
        }

        return user;
      }));
  }

}
