import { Component, OnInit } from '@angular/core';
import {
    Router,
    // import as RouterEvent to avoid confusion with the DOM Event
    Event as RouterEvent,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    NavigationError
  } from '@angular/router'
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    loading = true;
    constructor() {
    }
    /* // Shows and hides the loading spinner during RouterEvent changes
    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.loading = true
            console.log("true", event)
        }
        if (event instanceof NavigationEnd) {
            this.loading = false
            // console.log("false", event)
        }
        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.loading = false
            console.log("false false")
        }
        if (event instanceof NavigationError) {
            this.loading = false
            console.log("false false false")
        }
    } */
    ngOnInit() {
    }
}
