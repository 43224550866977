/*!
 **********************************************************************************************
 * Company name: Jenex Technologies
 *
 * @file : auth.guard.ts
 * @file description: when url is loaded this service is checked condition and return redirect link of which module 
 * is show first time;This method is called from app-routing.module.ts.
 * @author : Asha Bheda
 *
 *********************************************************************************************/
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../login/authentication.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    
    constructor(private auth: AuthenticationService, private router: Router) {
    }   
    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (localStorage.getItem('isLoggedin')== "true") {
            return true;
        }
        else{
            this.router.navigate(['/login']);
            console.log("else")
            return false;
        }
    }
}
